<template>
  <div class="container">
    <div>
      <form>
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
            <p class="modal-card-title">Vos informations</p>
          </header>
          <section class="modal-card-body">
            <b-field label="Nom">
              <b-input type="text" v-model="form.name" placeholder="Votre nom" maxlength="50" :has-counter="false" required>
              </b-input>
            </b-field>
            <b-field label="Adresse mail">
              <b-input type="email" v-model="form.email" placeholder="Votre adresse mail" maxlength="50" :has-counter="false" required>
              </b-input>
            </b-field>
            <b-field label="Adresse postale">
              <b-input type="text" v-model="form.address" placeholder="Votre adresse postale" maxlength="60" :has-counter="false" required>
              </b-input>
            </b-field>
            <b-field label="Téléphone">
              <b-input v-model="form.phone" placeholder="Votre numéro de téléphone" maxlength="25" :has-counter="false" required>
              </b-input>
            </b-field>
          </section>
        </div>
      </form>
    </div>
    <div>
      <form>
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
            <p class="modal-card-title">Votre commande</p>
          </header>
          <section class="modal-card-body">
            <b-field>
              Nombre de produits commandés :
              {{ nbMyProducts }}
            </b-field>
            <b-field>
              <strong>Prix total : {{ Number(myTotalPrice).toFixed(2) }} CHF</strong>
            </b-field>
            <b-field>
              <b-button type="is-success" @click="saveOrder">Valider</b-button>
            </b-field>
          </section>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import router from '@/router';
import notification from "@/mixins/notification";

export default {
  name: 'ShowOrderValidation',
  data: () => ({
    form: {},
    user: {}
  }),
  computed: {
    ...mapGetters(['myProducts', 'nbMyProducts', 'myTotalPrice'])
  },
  mixins: [notification],
  beforeMount() {
    this.$store.commit('ORDER_OK', false);
    this.$store.dispatch('getUser')
      .then((data) => {
        this.user = data;
        this.form = this.user;
      })
      .catch(() => {
        this.showError('Impossible de charger le compte.')
        this.$store.dispatch('logout')
      });
  },
  methods: {
    async saveOrder() {
      const data = {
        name: this.form.name,
        email: this.form.email,
        address: this.form.address,
        phone: this.form.phone,
        id: this.user.id
      };
      await this.$store.dispatch('updateAccount', data)
        .then(() => {
          const order = this.myProducts.map((product) => ({
            ...product,
            pivot: {quantity: product.cpt}
          }));
          this.$store.dispatch('order', order)
            .then(() => {
              router.push({name: 'ConfirmationOrder'});
            })
            .catch(()=>{
              this.showError('Impossible de passer commande.')
            });
        }).catch(()=> {
          this.showError('La commande est impossible avec vos informations.')
        });
    }
  }
};
</script>
